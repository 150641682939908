import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { Grid, Container } from '@material-ui/core';

import Layout from '../components/Layout';

import SEO from '../components/SEO';
import Ribbon from '../components/Ribbon';

const Privacy = ({ path, location }) => {
  const { loodgieter, pdf } = useStaticQuery(graphql`
    query {
      loodgieter: file(relativePath: { eq: "bathroom/bathroom-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pdf: allFile(filter: { extension: { eq: "pdf" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `);

  const seo = {
    title: 'Privacy - E S I - Installatietechniek B.V.',
    description:
      'Benieuwd naar de privacy regels van E S I - Installatietechniek B.V., lees alles hier.',
    pathname: path,
    location,
  };

  return (
    <>
      <Layout>
        <SEO
          title={seo.title}
          description={seo.description}
          location={location}
        />

        <Ribbon
          heading='Privacy'
          pitchLine='Benieuwd naar de privacy regels van E S I - Installatietechniek B.V., lees alles hier of bel gerust even met ons.'
          fluid={loodgieter.childImageSharp.fluid}
        />
        <Container fixed>
          <Grid container className='mb-10x mt-10x'>
            <Grid item xs={12}>
              <a href={pdf.edges[0].node.publicURL} download>
                <p className='center-xs pb-4x bb'>
                  <strong>
                    <i>
                      📑{' '}
                      <span className='text--underline'>
                        Download {pdf.edges[0].node.name}
                      </span>
                    </i>
                  </strong>
                </p>
              </a>
              <h2 className='mt-4x'>
                Privacy statement E S I – Installatietechniek B.V.
              </h2>
              <p>
                Bij het gebruik van onze website en/of diensten verkrijgen wij
                bepaalde gegevens van u. Dat kunnen persoonsgegevens zijn. Wij
                bewaren en gebruiken uitsluitend de persoonsgegevens die
                rechtstreeks door u worden opgegeven, in het kader van de door u
                gevraagde dienst, of waarvan bij opgave duidelijk is dat ze aan
                ons worden verstrekt om te verwerken.
              </p>
              <p>
                Wij verzamelen de volgende gegevens voor de in deze
                privacyverklaring genoemde doelen:
              </p>
              <ul>
                <li>NAW gegevens</li>
                <li>Telefoonnummer</li>
                <li>Factuuradres</li>
                <li>Emailadres</li>
                <li>Betalingsgegevens</li>
                <li>KvK-nummer</li>
                <li>btw-nummer</li>
                <li>overige gegevens die u ons verstrekt</li>
                <li>Websitegegevens </li>
              </ul>
              <h3>Doeleinden</h3>
              <p>Wij verwerken uw persoonsgegevens voor de volgende doelen: </p>
              <ul>
                <li>Het afhandelen van uw betaling</li>
                <li>Verzenden van informatieve mailing, niet repeterend</li>
                <li>
                  U te kunnen bellen of e-mailen indien dit nodig is om onze
                  dienstverlening uit te kunnen voeren
                </li>
                <li>
                  U te informeren over wijzigingen van onze diensten en
                  producten
                </li>
                <li>Om goederen en diensten bij u af te leveren</li>
                <li>
                  Persoonsgegevens als wij hier wettelijk toe verplicht zijn
                </li>
              </ul>
              <h3>Bewaartermijnen</h3>
              <p>
                Wij bewaren de verstrekte gegevens niet langer dan noodzakelijk.
                Wij hanteren een bewaartermijn van zeven jaar. Deze
                bewaartermijn hanteren wij om deze gelijk te trekken op grond
                van de belastingwetgeving. Deze beslissing is gemaakt om het
                gebruiksgemak van u en dat van onze onderneming te vergroten.
              </p>
              <h3>Derden</h3>
              <p>
                Wij werken met de volgende applicaties om onze dienstverlening
                te kunnen realiseren.
              </p>
              <ul>
                <li>
                  WeFact – voor facturatie Zie ook de privacy statement:
                  <a href='https://www.wefact.nl/privacy-verklaring/'>
                    https://www.wefact.nl/privacy-verklaring/
                  </a>
                </li>
                <li>
                  Exact – voor onze boekhouding Zie ook de privacy statement:
                  <a href='https://www.exact.com/privacy-statement'>
                    https://www.exact.com/privacy-statement
                  </a>
                </li>
              </ul>
              <h3>Cookies</h3>
              <p>
                Op onze website wordt gebruik gemaakt van functionele cookies om
                de website prestaties te bevorderen. Uw gebruik van onze website
                vatten wij op als toestemming voor dit gebruik van de
                functionele cookies. U bent vrij om cookies uit te schakelen
                middels uw browser. Houd er wel rekening mee dat het mogelijk is
                dat onze website dan niet meer optimaal werkt.
              </p>
              <h3>Wijzigingen in deze privacyverklaring</h3>
              <p>
                Wij behouden ons het recht voor om wijzigingen aan te brengen in
                deze privacyverklaring. Het verdient aanbeveling om deze
                privacyverklaring geregeld te raadplegen, zodat u van deze
                wijzigingen op de hoogte bent.
              </p>
              <h3>Inzage en wijzigen van uw gegevens </h3>
              <p>
                Voor vragen over ons privacybeleid of vragen omtrent inzage en
                wijzigingen in (of verwijdering van) uw persoonsgegevens kunt u
                te allen tijde contact met ons opnemen via de contactgegevens
                onderaan deze privacy statement.{' '}
              </p>
              <p>
                U kunt ons ook een verzoek toesturen om deze gegevens in te
                zien, te wijzigen of te verwijderen. Ook kunt u een verzoek
                indienen om een gegevensexport op te vragen voor gegevens die
                wij met uw toestemming gebruiken, of gemotiveerd aangeven dat u
                de verwerking van persoonsgegevens door ons wil laten beperken.{' '}
              </p>
              <p>
                Om misbruik te voorkomen kunnen wij u daarbij vragen om u
                adequaat te identificeren. Wanneer het gaat om inzage in
                persoonsgegevens gekoppeld aan een cookie, dient u een kopie van
                de cookie in kwestie mee te sturen. U kunt deze terug vinden in
                de instellingen van uw browser. Indien de gegevens niet kloppen,
                kunt u ons verzoeken om de gegevens te wijzigen of te laten
                verwijderen.{' '}
              </p>
              <h3>Autoriteit Persoonsgegevens </h3>
              <p>
                Natuurlijk helpen wij u graag verder als u klachten heeft over
                de verwerking van uw persoonsgegevens. Op grond van de
                privacywetgeving heeft u ook het recht om een klacht in te
                dienen bij de Autoriteit Persoonsgegevens tegen deze
                verwerkingen van persoonsgegevens. U kunt hiervoor contact
                opnemen met de Autoriteit Persoonsgegevens.{' '}
              </p>
              <h3>Contactgegevens</h3>
              <p>
                E S I – Installatietechniek B.V.
                <br />
                <a href='mailto:info@esi-install.nl'>info@esi-install.nl</a>
                <br />
                KvK - 81154089
              </p>
              <p>
                Privacy statement versie: 2022 <br />
                Datum: 28-12-2021
              </p>
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </>
  );
};

Privacy.propTypes = {
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Privacy;
